import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'MENUITEMS.DASHBOARD.TEXT',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'clients',
    title: 'MENUITEMS.CLIENTS.TEXT',
    icon: 'users',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'commands',
    title: 'MENUITEMS.COMMANDS.TEXT',
    icon: 'shopping-cart',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'providers',
    title: 'MENUITEMS.PROVIDERS.TEXT',
    icon: 'camera',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'products',
    title: 'MENUITEMS.PRODUCTS.TEXT',
    icon: 'briefcase',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: 'accounts',
    title: 'MENUITEMS.ACCOUNTS.TEXT',
    icon: 'settings',
    class: '',
    groupTitle: false,
    submenu: [],
  },
  {
    path: '',
    title: 'SMS',
    icon: 'send',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      // {
      //   path: '404',
      //   title: 'SMS envoyès',
      //   icon: 'users',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   submenu: [],
      // },
      {
        path: 'sms',
        title: 'Templates des SMS',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
    {
    path: '',
    title: 'Email',
    icon: 'mail',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'emails',
        title: 'Emails envoyés',
        icon: 'users',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      },
      {
        path: 'email',
        title: 'Templates d\'emails',
        icon: 'schedule',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.FINANCE.TEXT',
    icon: 'command',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'finances',
        title: 'Facturation',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      },
      {
        path: 'transaction',
        title: 'Transaction',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: [],
      }
    ],
  },
];
